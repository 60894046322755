#card-view-modal {
  --modal-width: 30em;
  --modal-padding: 0;
  --game-card-scale: 3;
}

#card-view-modal.padded {
  --modal-padding: 0 0 8em;
}

#card-view-modal header {
  padding: 1.5em 1.5em 0;
  margin-bottom: 1em;
}

#card-view-modal uf-icon[icon=cancel] {
  width: 2em;
}

#card-view-modal .swiper {
  padding-top: 3em;
}

#card-view-modal > uf-swiper-new .swiper-button-prev, #card-view-modal uf-swiper-new .swiper-button-next {
  --side-position: -4.5em;
  top: 43%;
}

#card-view-modal > uf-swiper-new .swiper-button-prev {
  left: var(--side-position);
}

#card-view-modal > uf-swiper-new .swiper-button-next {
  right: var(--side-position);
}

#card-view-modal footer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  margin-top: 5em;
  padding-bottom: 2.5em;
}

#card-view-modal footer button {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  height: 2.5em;
  padding: 0 1em;
  line-height: 1;
}

#card-view-modal footer uf-icon {
  width: 1.125em;
}

@media (max-width: 500px) {

  #card-view-modal {
    --game-card-scale: 2.8;
    --modal-width: 100%;
  }

  #card-view-modal > uf-swiper-new .swiper-button-prev, #card-view-modal uf-swiper-new .swiper-button-next {
    display: none;
  }
  
}