onboarding-info {
  display: block;
  padding: 5.5em 0 5.5em;
}

onboarding-info > div {
  max-width: 29.126em;
  margin: 0 auto;
  padding: 0 2em;
}

onboarding-info h1 {
  text-align: center;
  font-size: 2.25em;
  line-height: 1;
}

onboarding-info game-scoring {
  margin-top: 3em;
}

onboarding-info footer {
  position: fixed;
  bottom: 3.125em;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
}

onboarding-info uf-tooltip {
  width: 13.75em;
}

onboarding-info points-svg, onboarding-info howtowin-svg {
  display: block;
  margin: 3em auto 0;
  width: 21.45em;
  max-width: 100%;
}

onboarding-info #howtowin-graphic {
  position: relative;
}

onboarding-info #howtowin-graphic uf-tooltip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 12.75em;
  width: 16.85em;
  max-width: none;
}