stage-view {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 500px) {
  stage-view header-bar h3 {
    font-size: 1em;
  }
}

stage-view section {
  display: flex;
  width: 100%;
  max-width: 25.125em;
  flex-direction: column;
  row-gap: 1em;
  justify-content: center;
  margin: 1em auto;
}

#stages-card-link {
  margin-bottom: 2em;
}

#user-leagues header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.75em;
}

#user-leagues header h3 {
  line-height: 1;
}

#user-leagues header uf-icon {
  width: 2em;
}

#user-leagues section {
  margin: 0;
}

#user-leagues p {
  color: var(--color-soft-text);
  width: 20.3125em;
  margin: auto;
  text-align: center;
  padding: 2em 0;
}

stage-view footer {
  margin-top: 2em;
}

stage-view uf-icon[icon="eighteen"] {
  margin: 0 auto;
  width: 2.875em;
  height: 2.875em;
}

stage-view uf-icon[icon="twentyOne"] {
  margin: 0 auto;
  width: 2.875em;
  height: 2.875em;
}

stage-view footer p {
  width: 20em;
  margin: 0.5em auto;
  text-align: center;
  font-weight: 500;
  font-size: 0.875em;
  color: var(--color-soft-text)
}

stage-view footer a {
  color: var(--color)
}