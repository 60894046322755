squad-browse-view {
  --margin: calc(var(--game-padding) * 1.5);
  -webkit-user-select: none;
  user-select: none;
}

squad-browse-view header-bar {
  margin-bottom: var(--margin);
}
squad-browse-view header-bar h1 {
  font-size: 1.5em;
}
squad-browse-view header-bar p {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
}
squad-browse-view [slot] p.active {
  color: var(--color-active);
}
squad-browse-view [slot="left"],
squad-browse-view [slot="right"] {
  display: flex;
  align-items: center;
}
squad-browse-view [slot=left] {
  column-gap: 1.25em;
}
squad-browse-view [slot=right] {
  column-gap: 2em;
}
squad-browse-view [slot="left"] uf-icon,
squad-browse-view [slot="right"] uf-icon {
  flex-shrink: 0;
}
squad-browse-view uf-icon[icon=countdown] {
  width: 1.5em;
}
squad-browse-view uf-icon[icon=chevron] {
  width: 1.25em;
}

@media (max-width: 500px) {
  squad-browse-view header-bar h1, squad-browse-view header-bar p {
    font-size: 1em;
  }
  squad-browse-view [slot=right] {
    column-gap: 1em;
  }
}

squad-browse-view squad-collection {
  height: calc(var(--real100vh, 100vh) - var(--game-navbar-height) - 3.5em - (var(--game-padding) * 2) - var(--margin));
}

squad-browse-view #loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

squad-browse-view loading-spinner {
  width: 4em;
  margin-bottom: 6em;
}