info-view [slot=left] {
  display: flex;
  align-items: center;
  column-gap: 2em;
}

info-view [slot=left] h3 {
  display: flex;
  align-items: center;
  column-gap: 2em;
}

info-view uf-icon[icon=chevron] {
  height: 1.5em;
  width: 1.5em;
  padding: 0;
}

@media (max-width: 500px) {
  info-view header-bar h3 {
    font-size: 1em;
  }
}