@import "../fonts/fonts.css";

:root {
  --font: "DM Sans";
  --fontsize: 16px;
  --smallfontsize: 0.875em;
  --fontweight: 400;
  --headingfont: var(--font);
  --headingweight: 700;
  --lineheight: 1.5;
  --color: #F6F3EE;
  --background: #151515;

  --game-card-width: 6.45em;
  --game-card-height: 6.7em;

  --game-card-scale: 1;
}

html {
  font-size: var(--fontsize);
  line-height: var(--lineheight);
  -webkit-text-size-adjust: 100%;
}

body {
  position: fixed;
  width: 100vw;
  height: var(--real100vh, 100vh);
  margin: 0;
  overflow: hidden;
  font-family: var(--font);
  font-weight: var(--fontweight);
  color: var(--color);
  -webkit-tap-highlight-color: transparent;
  background-color: var(--background);
}