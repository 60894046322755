points-breakdown-view header-bar {
  margin-bottom: 3.125em;
}

points-breakdown-view header-bar uf-icon {
  width: 1.25em;
}

points-breakdown-view header-bar h1 {
  font-size: 1.5em;
}

points-breakdown-view header, points-breakdown-view section {
  width: 25.125em;
  max-width: 100%;
  margin: 0 auto;
}

points-breakdown-view header {
  margin-bottom: 2.5em;
}

points-breakdown-view section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1em;
}

points-breakdown-view h3 {
  margin: 0;
  color: var(--color-soft-text);
  font-size: 1.125em;
  font-weight: 700;
  text-transform: uppercase;
}

points-breakdown-view h3:not(:first-of-type) {
  margin-top: 1.75em;
}

points-breakdown-view p {
  margin: 0;
  font-size: 1.125em;
  font-weight: 500;
}

points-breakdown-view .row {
  display: flex;
  justify-content: space-between;
}

points-breakdown-view .row p:first-child {
  color: var(--color);
  text-align: left;
}

points-breakdown-view .row p:last-child {
  color: var(--color-active);
  text-align: right;
}

points-breakdown-view .row p:last-child.neutral {
  color: #858585;
}

points-breakdown-view .row p:last-child.negative {
  color: var(--color-error);
}

points-breakdown-view #modifier {
  margin-top: 4em;
}

points-breakdown-view #modifier h2 {
  display: flex;
  align-items: center;
  column-gap: 1.25em;
  font-size: 1em;
  margin-bottom: 2em;
}

points-breakdown-view #modifier h2 span {
  font-size: 1.125em;
}

points-breakdown-view #modifier #modifier-label {
  --size: 1.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  background-color: #fff;
  padding: 0.25em;
  cursor: pointer;
}