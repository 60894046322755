onboarding-intro-pack {
  display: block;
  height: 100%;
}

onboarding-intro-pack .center-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

onboarding-intro-pack loading-spinner {
  width: 4em;
}

onboarding-intro-pack #skip-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25em 2.5em;
}

onboarding-intro-pack #skip-button span {
  font-size: 1.125em;
}

onboarding-intro-pack #error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}