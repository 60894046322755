game-area {
  display: flex;
  height: 100%;
  column-gap: var(--game-padding);
  position: relative;
  user-select: none;
}

game-area #loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

game-area loading-spinner {
  width: 4em;
  margin-bottom: 6em;
}

@media (max-width: 650px) {
  game-area {
    flex-direction: column;
    padding-bottom: 13em;
  }
}

@media (max-width: 500px) {
  game-area {
    min-height: 39.5em;
  }
} 