onboarding-complete {
  text-align: center;
}

onboarding-complete > div {
  max-width: 29.75em;
  padding: 3.25em 2em 4em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

onboarding-complete #complete {
  color: var(--color-active);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.75em;
  margin-bottom: 2em;
}

onboarding-complete #complete-icon {
  --size: 3.5em;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-active);
}

onboarding-complete #complete-icon uf-icon {
  width: 1.5em;
}

onboarding-complete #complete span {
  font-size: 1.5em;
  font-weight: 700;
}

onboarding-complete h1 + p {
  margin-top: 0.5em;
}

onboarding-complete p {
  margin: 0;
  line-height: 1.3;
}

onboarding-complete #modifiers {
  margin: 3.875em 0 4.875em;
  display: flex;
  flex-direction: column;
  row-gap: 3.125em;
}

onboarding-complete #modifiers > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

onboarding-complete game-card-front {
  margin-bottom: 1.625em;
}

onboarding-complete h2 {
  font-size: 1.5em;
  line-height: 1.3;
}

onboarding-complete #modifiers p {
  margin: 0.5em 0 0;
}

onboarding-complete a {
  margin-top: 2.5em;
}