game-view {
  --game-navbar-height: 6em;
}

main#game {
  min-height: calc(var(--real100vh, 100vh) - var(--game-navbar-height));
  /* webpackIgnore: true */
  background-image: url("/game-background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  padding: var(--game-padding);
}

#nav {
  position: sticky;
  top: 0;
  z-index: 2;
}

@media (min-width: 1000px) {
  main#game {
    background-size: 80em;
  }
}

@media (max-width: 800px) {
  game-view {
    --fontsize: 12px;
    font-size: var(--fontsize);
  }
}

@media (max-width: 500px) {
  game-view {
    --game-navbar-height: 4em;
    --fontsize: 4vw;
  }
}