*, *:before, *:after { box-sizing: border-box; }
:focus { outline: none; }

header, section, aside, nav, main, article, figure, figcaption, details { display: block; }

h1, h2, h3, h4, h5, h6, p, ul, ol { margin-top: 0; }
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: var(--headingweight);
  font-family: var(--headingfont);
}

p, ul, ol { margin: 1em 0; }

a, a:visited { text-decoration: none; }
a, a img, textarea:focus, input:focus { outline: none; }
a, input[type="submit"], input[type="button"], button {
  cursor: pointer;
}

b, strong { font-weight: bolder; }

small { font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub { bottom: -0.25em; }
sup { top: -0.5em; }

img, video, audio {
  border-style: none;
  border: 0;
  max-width: 100%;
  display: block;
}
video { max-height: var(--real100vh, 100vh); }
audio:not([controls]) { display: none; height: 0; }

ul, ol { list-style-type: none; padding: 0; margin: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="submit"], input[type="button"], input[type="search"], input[type="text"], textarea { 
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -webkit-text-fill-color: var(--color);
  transition: background-color 5000s ease-in-out 0s;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

[type="checkbox"], [type="radio"] { padding: 0; }

svg:not(:root) { overflow: hidden; }

pre, code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

template, [hidden] { display: none; }