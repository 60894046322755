open-pack-overlay uf-overlay > div {
  width: 53.25em;
  max-width: 100%;
  margin: 5.5em auto;
}

open-pack-overlay header-bar {
  --header-bar-background: transparent;
}
open-pack-overlay header-bar uf-icon {
  width: 2em;
}
open-pack-overlay header-bar h2 {
  font-size: 2.25em;
}

open-pack-overlay #content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
open-pack-overlay #content > div {
  position: absolute;
  top: 0;
  padding-bottom: 5.5em;
}

open-pack-overlay #content > div#pack-area {
  z-index: 1;
}
open-pack-overlay #content > div#cards-area {
  z-index: 0;
}
open-pack-overlay #content > div#cards-area > div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

open-pack-overlay p {
  margin: 0;
}
open-pack-overlay p.hidden {
  visibility: hidden;
}

open-pack-overlay #pack {
  width: 20em;
  margin: 2.25em 0 1.75em;
  position: relative;
}

open-pack-overlay pack-item {
  cursor: pointer;
}

open-pack-overlay #animation {
  position: absolute;
  width: 157%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: -27em;
  left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 375 / 812;
}
@supports not (aspect-ratio: 375 / 812) {
  open-pack-overlay #animation::before {
    content: "";
    float: left;
    padding-top: 216.53333%;
  }
  open-pack-overlay #animation::after {
    content: "";
    display: block;
    clear: both;
  }
}

open-pack-overlay pack-item {
  width: 100%;
}

/* open-pack-overlay uf-swiper-new {
  width: 20.5em;
  max-width: 100%;
  margin: 3em 0;
} */
open-pack-overlay uf-swiper-new {
  --game-card-scale: 3;
  padding-bottom: 3em;
  width: 100vw;
}

open-pack-overlay uf-swiper-new .swiper {
  padding-top: 3em;
}

open-pack-overlay uf-swiper-new .swiper-navigation {
  top: 43%;
  width: 0;
  z-index: 1;
}

open-pack-overlay uf-swiper-new .swiper-button-prev, open-pack-overlay uf-swiper-new .swiper-button-next {
  --side-position: -19.425em;
}

open-pack-overlay uf-swiper-new .swiper-button-prev {
  left: var(--side-position);
}

open-pack-overlay uf-swiper-new .swiper-button-next {
  right: var(--side-position);
}

open-pack-overlay #cards-area button {
  padding: 0 1.625em;
  height: 2.5em;
  border-radius: 0.5em;
}

open-pack-overlay uf-tooltip {
  top: calc(100% - 0.5em);
}

@media (max-width: 500px) {
  open-pack-overlay header-bar h2 {
    font-size: 1.4em;
  }
  #swiper-navigation {
    display: none;
  }
}