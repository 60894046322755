lobby-view .floating-panel {
  position: absolute;
  bottom: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

lobby-view .floating-panel login-streak-card {
  margin: 0 auto;
  width: calc(100vw - 2em);
  max-width: 38.75em;
  z-index: 4;
}

lobby-view #header-bar {
  --grid-width: 38.75em;
  --header-bar-columns: 1fr 0fr 1fr;
  margin: 0 auto var(--game-padding);
  width: var(--grid-width);
}

lobby-view header-bar p {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
}

lobby-view [slot=left] p {
  text-transform: uppercase;
}

lobby-view [slot=right] {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

lobby-view uf-icon[icon=countdown] {
  width: 1.5em;
}

lobby-view uf-icon[icon=chevron] {
  width: 0.85em;
  transform: rotate(180deg);
}

lobby-view uf-icon[icon=eighteen] {
  width: 2.875em;
  margin: 0 auto;
}

lobby-view uf-icon[icon=twentyOne] {
  width: 2.875em;
  margin: 0 auto;
}

lobby-view #countdown {
  font-size: 1.25em;
  color: var(--color-countdown);
}

/* 
  myTeam          1.72
  Bengals sponsor 2.168
  ReferAFriend    3.64
  LoginStreak     3.76
  [discord/clash] 2.5
*/

lobby-view .grid {
  --grid-width: 38.75em;
  width: var(--grid-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: calc(var(--grid-width) / 2.067) calc(var(--grid-width) / 2.067);
  grid-template-rows: calc(var(--grid-width) / 1.72) calc(var(--grid-width) / 3.64) calc(var(--grid-width) / 2.5) calc(var(--grid-width) / 1.7714);
  row-gap: calc(var(--grid-width) / 31);
  column-gap: calc(var(--grid-width) / 31);
  justify-content: space-between;

  --border-radius: 1.25em;
  --border-radius-small: 0.625em;
  --grid-item-bar-height: calc(var(--grid-width) / 7.381);
  --grid-item-bar-small-height: calc(var(--grid-width) / 16);
}

lobby-view .grid#bengals {
  grid-template-rows: calc(var(--grid-width) / 1.72) calc(var(--grid-width) / 1.91) calc(var(--grid-width) / 3.76) calc(var(--grid-width) / 2.5);

}

lobby-view .grid#chargers {
  grid-template-rows: calc(var(--grid-width) / 1.72) calc(var(--grid-width) / 3.76) calc(var(--grid-width) / 2.5);
}

lobby-view .grid-item-image {
  height: calc(100% - var(--grid-item-bar-height));
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-color: var(--color-secondary);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

lobby-view .grid-item-image.full {
  height: 100%;
  border-radius: var(--border-radius);
}

lobby-view .grid-item-bar {
  height: var(--grid-item-bar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-grid-item-bar);
  padding: 0 1.5em;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

lobby-view .grid-item-bar p {
  margin: 0;
  color: var(--color-grid-item-bar-font);
  font-size: 1.5em;
}

lobby-view .grid-item-bar p span {
  color: var(--color-soft-text);
}

lobby-view .grid-item-image-small {
  height: calc(100% - var(--grid-item-bar-small-height));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}

lobby-view .grid-item-bar-small {
  height: var(--grid-item-bar-small-height);
  background-color: var(--color-grid-item-bar-small);
  padding: 0 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
}

lobby-view .grid-item-bar-small p {
  margin: 0;
  color: var(--color-grid-item-bar-font-small);
  font-size: 1.25em;
}

lobby-view .grid-item-bar p .has-prize {
  color: var(--color-grid-item-bar-font-small-prize);
}

lobby-view aside {
  position: absolute;
  top: 0.75em;
  right: 1em;
  display: flex;
  align-items: center;
  column-gap: 0.5em;
}

lobby-view aside span {
  font-size: 0.875em;
}

lobby-view uf-icon[icon=bars] {
  width: 1.5em;
}

lobby-view #sponsor-header {
  grid-column-end: span 2;
  margin: 0 auto 1em;
}

lobby-view #sponsor-header p {
  font-size: 0.625em;
  margin: 0 auto;
  text-align: center;
}

lobby-view #sponsor-header img {
  margin: 0 auto;
}

lobby-view #squad {
  grid-column-end: span 2;
  grid-row-start: 1;
  position: relative;
}

lobby-view #sponsor {
  grid-column-end: span 2;
  grid-row-start: 2;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-image: url('./assets/lobby-bengals-betfred.png');
  border-radius: var(--border-radius-small) var(--border-radius-small) var(--border-radius-small) var(--border-radius-small);
}

lobby-view refer-friends-card {
  grid-column-end: span 2;
}

lobby-view login-streak-card {
  grid-column-end: span 2;
}

lobby-view #discord {
  grid-column: 1 / span 1;
}

lobby-view #discord .grid-item-image-small {
  background-image: url("https://ufpremier22prodstorage.blob.core.windows.net/ultimatefan/lobby_thumbnail_1.png");
}

lobby-view #clash {
  grid-column: 2 / span 1;
}

lobby-view #clash .grid-item-image-small {
  background-image: url("https://ufpremier22prodstorage.blob.core.windows.net/ultimatefan/lobby_thumbnail_2.png");
}

lobby-view #offers {
  grid-column-end: span 2;
  grid-row-start: 4;
  background-image: url('./assets/UF-ExclusiveOffers-1920x960.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  border-radius: var(--border-radius-small);
}

lobby-view #offers .grid-item-image-small {
  background-image: url("https://www.ultimatefan.com/uk/offers/");
}

lobby-view #age-rating {
  grid-column-end: span 2;
  margin-top: 2em;
  grid-row-start: 5;
}

@media (max-width: 600px) {
  lobby-view .grid {
    grid-template-columns: calc(var(--grid-width) / 2.075) calc(var(--grid-width) / 2.075);
    grid-template-rows: calc(var(--grid-width) / 1.8) calc(var(--grid-width) / 3.82) calc(var(--grid-width) / 2.44) calc(var(--grid-width) / 2);
    row-gap: calc(var(--grid-width) / 30);
    --grid-width: calc(100vw - 2em);
    --grid-item-bar-height: calc(var(--grid-width) / 10);
    --grid-item-bar-small-height: calc(var(--grid-width) / 13);
  }

  lobby-view .grid-item-bar p {
    font-size: 1em;
  }

  lobby-view .grid-item-bar-small p {
    font-size: 0.83em;
  }
}

/* 
    myTeam          1.72
    Bengals sponsor 2.168
    ReferAFriend    2.6
    LoginStreak     2.7
    [discord/clash] 2.5
  */

@media (max-width: 500px) {
  lobby-view header-bar {
    --grid-width: calc(100vw - 2em);
  }

  lobby-view header-bar p {
    font-size: 1em;
  }

  lobby-view .grid {
    --grid-width: calc(100vw - 2em);
    grid-template-rows: calc(var(--grid-width) / 1.8) calc(var(--grid-width) / 2.5) calc(var(--grid-width) / 2.44) calc(var(--grid-width) / 2);
  }

  lobby-view .grid#bengals {
    grid-template-rows: calc(var(--grid-width) / 1.72) calc(var(--grid-width) / 1.88) calc(var(--grid-width) / 2.3) calc(var(--grid-width) / 2.5);
  }

  lobby-view .grid#chargers {
    grid-template-rows: calc(var(--grid-width) / 1.72) calc(var(--grid-width) / 2.3) calc(var(--grid-width) / 2.5);
  }

}