end-view {
  width: 100vw;
  height: var(--real100vh, 100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  /* webpackIgnore: true */
  background-image: url("/game-background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  text-align: center;
}

end-view > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3em;
  width: 21.5em;
  padding: 1.5em;
  max-width: 100%;
}

end-view logo-uf {
  width: 5em;
}

end-view logo-bengals {
  width: 10em;
}

end-view logo-chargers {
  width: 11em;
}

h1 {
  margin: 0;
  margin-bottom: 1.5em;
  line-height: 1.3;
}

p {
  font-size: 1.2em;
}

@media (min-width: 1000px) {
  end-view {
    background-size: 80em;
  }
}

@media (max-width: 800px) {
  end-view {
    --fontsize: 12px;
    font-size: var(--fontsize);
  }
}

@media (max-width: 500px) {
  end-view {
    --fontsize: 4vw;
  }
}