leaderboard-position-list-view {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

leaderboard-position-list-view [slot=left] {
  display: flex;
  align-items: center;
  column-gap: 2em;
}

leaderboard-position-list-view uf-icon[icon=chevron] {
  width: 1.25em;
}

leaderboard-position-list-view uf-icon[icon=info] {
  width: 2em;
}

@media (max-width: 500px) {
  leaderboard-position-list-view header-bar h3 {
    font-size: 1em;
  }
  leaderboard-position-list-view [slot=left] {
    column-gap: 1em;
  }
}

leaderboard-position-list-view section {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  justify-content: center;
  margin: 1em auto 6em auto;
  max-width: 25.125em;
}

leaderboard-position-list-view #loading {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

leaderboard-position-list-view loading-spinner {
  margin: 2em auto;
  width: 3em;
}

leaderboard-position-list-view div.titles {
  margin: 1.125em auto -0.125em auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 25.125em;
}

leaderboard-position-list-view div.titles > p {
  margin: 0 auto;
}

leaderboard-position-list-view div.titles > p:first-child {
  width: 60%;
}

leaderboard-position-list-view div.leaderboard-cards {
  row-gap: 0.5em;
}

leaderboard-position-list-view router-link {
  display: block;
}

leaderboard-position-list-view .user-position-entry-floating {
  margin: 0 auto;
  position: absolute;
  bottom: 2em;
  width: 100%;
  max-width: 25.125em;
}

@media (max-width: 500px) {
  leaderboard-position-list-view .user-position-entry-floating {
    max-width: 23.125em;
  }
}

leaderboard-position-list-view footer p {
  margin: 1em auto;
  font-size: 0.875em;
  font-weight: 400;
  color: var(--color-soft-text);
  text-align: center;
}