@font-face {
	font-family: 'DM Sans';
	font-weight: 400;
	font-style: normal;
	src: url('./DMSans-Regular.ttf');
}

@font-face {
	font-family: 'DM Sans';
	font-weight: 400;
	font-style: italic;
	src: url('./DMSans-RegularItalic.ttf');
}

@font-face {
	font-family: 'DM Sans';
	font-weight: 500;
	font-style: normal;
	src: url('./DMSans-Medium.ttf');
}

@font-face {
	font-family: 'DM Sans';
	font-weight: 500;
	font-style: italic;
	src: url('./DMSans-MediumItalic.ttf');
}

@font-face {
	font-family: 'DM Sans';
	font-weight: 700;
	font-style: normal;
	src: url('./DMSans-Bold.ttf');
}

@font-face {
	font-family: 'DM Sans';
	font-weight: 700;
	font-style: italic;
	src: url('./DMSans-BoldItalic.ttf');
}