info-contest-view {
  margin: 2em auto;
  display: flex;
  width: 100%;
  max-width: 25em;
  flex-direction: column;
  row-gap: 1em;
}

info-contest-view header div.clash-image {
  margin: 2em auto;
  width: 10.3125em;
  height: 8.4375em;
  /* webpackIgnore: true */
  background: url("/logo-clash.png");
  background-size: 10.3125em 8.4375em;
}

info-contest-view header h3 {
  font-size: 0.8125em;
  font-weight: 500;
}

info-contest-view header h1 {
  font-size: 2em;
  font-weight: 700;
}

info-contest-view #loading {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

info-contest-view loading-spinner {
  margin: 2em auto;
  width: 3em;
}

info-contest-view section {
  display: flex;
  width: 100%;
  max-width: 25em;
  flex-direction: column;
  row-gap: 1em;
  justify-content: center;
  margin: 1em auto;
}

info-contest-view .heading {
  display: flex;
  justify-content: space-between;
}

info-contest-view .fixtures p {
  margin: 1em auto 0 auto;
  color: var(--color);
  font-size: 1.125em;
  font-weight: 700;
}

info-contest-view .fixtures .row {
  display: flex;
  justify-content: space-between;
}

info-contest-view .fixtures .row p {
  margin: 0;
  font-size: 1.125em;
  font-weight: 400;
}

info-contest-view .fixtures .row p:first-child {
  color: var(--color);
  text-align: left;
}

info-contest-view .fixtures .row p:last-child {
  color: var(--color-soft-text);
  text-align: right;
}

info-contest-view .heading p {
  margin: 0;
  color: var(--color);
  font-size: 1.125em;
  font-weight: 700;
}

info-contest-view .heading p:first-child {
  text-align: left;
}

info-contest-view .heading p:last-child {
  text-align: right;
}

info-contest-view .prize-breakdown .row {
  display: flex;
  justify-content: space-between;
}

info-contest-view .prize-breakdown .row p {
  margin: 0;
  font-size: 1.125em;
  font-weight: 500;
}

info-contest-view .prize-breakdown .row p:first-child {
  color: var(--color);
  text-align: left;
}

info-contest-view .prize-breakdown .row p:last-child {
  color: var(--color-active);
  text-align: right;
}

info-contest-view footer p {
  margin: 1em auto;
  font-size: 0.875em;
  font-weight: 400;
  color: var(--color-soft-text);
  text-align: center;
}