join-league-view uf-icon[icon=chevron] {
  width: 1.5em;
  height: 1.5em;
}

join-league-view p {
  margin: 0;
  text-align: center;
  font-weight: 400;
}

join-league-view h2 {
  font-size: 1.5em;
}

@media (max-width: 500px) {
  join-league-view header-bar h2 {
    font-size: 1em;
  }
}

join-league-view form {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 6.5em;
  margin-top: 3em;
}

join-league-view main {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2.5em;
}

join-league-view main input {
  text-align: center;
  width: 25.125em;
}

join-league-view #loading {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

join-league-view loading-spinner {
  width: 3em;
}

join-league-view uf-modal section {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5em;
}

join-league-view div.modal-footer {
  width: 100%;
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
}

join-league-view div.modal-footer button.dark {
  background-color: var(--color-disabled-button);
  border-color: var(--color-disabled-button);
}