onboarding-lineup {
  --header-bar-margin: calc(var(--game-padding) * 1.5);
  display: block;
  padding: 7.5em var(--game-padding) 5.5em;
  height: var(--real100vh, 100vh);
  position: relative;
}

onboarding-lineup #skip-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25em 2.5em;
}

onboarding-lineup #skip-button span {
  font-size: 1.125em;
}

onboarding-lineup game-area.highlight-collection squad-collection {
  z-index: 2;
}

onboarding-lineup squad-collection {
  --squad-collection-columns: 3;
}

onboarding-lineup #background-dim {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

onboarding-lineup uf-tooltip#tooltip1 {
  width: 12em;
  position: absolute;
  right: 29em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

onboarding-lineup #tooltip2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25em;
}

onboarding-lineup #tooltip2 uf-tooltip {
  width: 13em;
}

@media (max-width: 1150px) {
  onboarding-lineup squad-collection {
    --squad-collection-columns: 2;
  }
  onboarding-lineup uf-tooltip#tooltip1 {
    right: 21em;
  }
}

@media (max-width: 1000px) {
  onboarding-lineup {
    --game-card-scale: 0.85;
  }
}

@media (max-width: 800px) {
  onboarding-lineup {
    --game-card-scale: 1;
  }
}

@media (max-width: 650px) {
  onboarding-lineup squad-collection {
    --collection-wrap: nowrap;
    --collection-headings-display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  onboarding-lineup uf-tooltip#tooltip1 {
    right: auto;
    top: auto;
    bottom: 23em;
    left: 50%;
    transform: translateX(-50%);
  }

}