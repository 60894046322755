transfer-summary {
  display: block;
  padding-bottom: calc(var(--selection-tray-height) + (var(--selection-tray-margin) * 2));
}

transfer-summary uf-icon[icon="cancel"] {
  width: 1.5em;
}

transfer-summary h1 {
  font-size: 1.5em;
  line-height: 1.3;
}

transfer-summary section {
  text-align: center;
  margin-top: 2em;
}

transfer-summary h2 {
  font-size: 2em;
  line-height: 0.9375;
  margin-bottom: 0.2em;
}

transfer-summary h2 + span {
  line-height: 0.9;
  color: #DCDAD5;
}

transfer-summary #selected {
  --game-card-scale: 1.6;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
  margin-top: 1.75em;
}

transfer-summary section p {
  margin-top: 5.1125em;
  font-weight: 500;
}

transfer-summary section p span {
  font-size: 1.25em;
}

transfer-summary transfer-tray [slot="body"] {
  display: flex;
  align-items: center;
  column-gap: 1.125em;
  padding-left: 0.625em;
}

transfer-summary transfer-tray uf-icon[icon="warning"] {
  display: flex;
  width: 2.6875em;
  flex-shrink: 0;
}

transfer-summary transfer-tray p {
  margin: 0;
  font-weight: 500;
  max-width: 18.75em;
  line-height: 1.2;
}

transfer-summary transfer-tray p span {
  font-size: 0.875em;
}

transfer-summary transfer-tray [slot="buttons"] {
  display: flex;
  align-items: stretch;
  height: 100%;
  column-gap: 1em;
  flex-shrink: 0;
}

transfer-summary transfer-tray [slot="buttons"] button {
  width: 5.25em;
  padding: 0;
  font-weight: 500;
}

transfer-summary transfer-tray [slot="buttons"] button[disabled] {
  background-color: #101010;
  border-color: #101010;
  color: var(--color-soft-text);
}

transfer-summary transfer-tray [slot="buttons"] button.dark {
  background-color: #101010;
  border-color: #101010;
}

transfer-summary button.active {
  background-color: #36FA73;
  border-color: #36FA73;
}

transfer-summary transfer-tray [slot="buttons"] button span {
  font-size: 0.875em;
}

transfer-summary uf-modal {
  --modal-width: 26.25em;
  --modal-height: 18.75em;
  --modal-padding: 3em 1.5em 1.625em;
  text-align: center;
  line-height: 1.3;
}

transfer-summary uf-modal > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

transfer-summary uf-modal h2 {
  font-size: 1.5em;
}

transfer-summary uf-modal p {
  margin: 0.875em auto 0;
  width: 100%;
  max-width: 18.5em;
}

transfer-summary uf-modal footer {
  display: flex;
  justify-content: center;
  column-gap: 1em;
}

transfer-summary uf-modal button {
  padding: 0;
  height: 3.625em;
  flex-grow: 1;
  border-radius: 1em;
}

transfer-summary uf-modal button.plain {
  border-width: 0.03125em;
}

transfer-summary uf-modal button.cancel {
  background-color: #FF284F;
  border-color: #FF284F;
}

transfer-summary uf-modal button span {
  font-size: 1.125em;
}

transfer-summary uf-modal loading-spinner {
  width: 3em;
  margin-bottom: 2em;
}

@media (max-width: 500px) {
  transfer-summary #selected {
    --game-card-scale: 1.2;
  }
}