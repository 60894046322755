mini-league-view uf-icon[icon=chevron] {
  height: 1.5em;
  width: 1.5em;
}

@media (max-width: 500px) {
  mini-league-view header-bar h2, mini-league-view header-bar span {
    font-size: 1em;
  }
}

mini-league-view main {
  position: relative;
  display: block;
}

mini-league-view #loading {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

mini-league-view loading-spinner {
  width: 3em;
}

mini-league-view div.left-header {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 1em;
}