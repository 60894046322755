squad-collection {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 1em;
  background-color: var(--color-background);
  flex-shrink: 0;

  --card-border-width: calc(0.2em * var(--game-card-scale, 1));
  --card-padding: calc(0.1em * var(--game-card-scale, 1));

  --columns: var(--squad-collection-columns, 5);
  --side-padding: 1.5em;
  --grid-gap: calc(1.5em - (var(--card-padding) * 2) - (var(--card-border-width) * 2));
  --navbar-height: 5.5em;


  width: calc((((var(--game-card-width) * var(--game-card-scale, 1)) + (var(--card-padding) * 2) + (var(--card-border-width) * 2)) * var(--columns)) + (var(--side-padding) * 2) + (var(--grid-gap) * (var(--columns) - 1)));
}

@media(max-width: 550px) {
  squad-collection {
    --columns: 3;
    --side-padding: 0.875em;
    --grid-gap: 0.875em;
  }
}

squad-collection uf-icon[icon=search] {
  width: 1.125em;
}

squad-collection uf-icon[icon=grid] {
  width: 1.625em;
}

squad-collection nav ul#search {
  display: flex;
  width: 100%;
  height: 3em;
  align-items: center;
  column-gap: 1em;
  padding: 0 1em;
  border-bottom: 0.1em solid #202020;
  margin-bottom: 0.5em;
}

squad-collection nav ul#search input {
  width: 100%;
  border-bottom: none;
}

squad-collection nav ul#filters {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 2.5em;
  padding: 0 0 1em;
}

squad-collection nav ul#filters > * {
  flex-shrink: 0;
}

squad-collection nav ul#filters > li {
  cursor: pointer;
  font-size: 0.875em;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 0.5em 0;
  color: var(--color-soft-text);
}

squad-collection nav ul#filters > li.selected {
  color: var(--color);
}

squad-collection nav ul#filters > li.selected::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  --size: 0.5em;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  background-color: var(--color-primary);
  bottom: -0.5em;
}

squad-collection #game-cards {
  overflow-y: auto;
}

squad-collection #game-cards header {
  display: var(--collection-headings-display, flex);
  padding: var(--side-padding);
  column-gap: 0.5em;
  width: 100%;
  align-items: baseline;
  line-height: 1;
}

squad-collection #game-cards header h1 {
  font-size: 1.5em;
}

squad-collection #game-cards header p {
  font-size: 1em;
  font-weight: 400;
  color: var(--color-soft-text);
  margin: 0;
}

squad-collection #game-cards > drop-area > div {
  padding: 0.5em var(--side-padding) 1.5em;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--grid-gap);
  flex-wrap: var(--collection-wrap, wrap);
}

squad-collection .card {
  padding: var(--card-padding);
  border-radius: 1.2em;
  border: 0.2em solid transparent;
}
squad-collection .card.active {
  border-color: var(--card-selected-outline);
}

squad-collection game-card-front:not([type=modifier]) {
  cursor: pointer;
}

squad-collection router-link#transfer {
  display: block;
  font-size: calc(var(--game-card-scale) * 1em);
  width: var(--game-card-width);
  height: var(--game-card-height);
  background-color: var(--color-primary);
  border-radius: 1em;
}

squad-collection router-link#transfer > div {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  row-gap: 0.875em;
}

squad-collection uf-icon[icon=transfers] {
  width: 1.5em;
}