app-onboarding {
  display: block;
  height: var(--real100vh, 100vh);
  background-color: var(--background);
  /* webpackIgnore: true */
  background-image: url("/game-background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  background-attachment: fixed;
}

@media (min-width: 1000px) {
  app-onboarding {
    background-size: 80em;
  }
}

@media (max-width: 800px) {
  app-onboarding {
    --fontsize: 12px;
    font-size: var(--fontsize);
  }
}

@media (max-width: 500px) {
  app-onboarding {
    --fontsize: 4vw;
  }
}