uf-swiper-new {
  display: block;
}

.swiper {
  --swiper-theme-color: var(--color);
  width: 100%;
  height: 100%;
  position: static;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  font-size: initial;
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-size: 0.5em;
  --swiper-pagination-bullet-horizontal-gap: 0.25em;
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-pagination-bullet-inactive-opacity: 1;
  position: relative;
  align-items: center;
  border: 0.12em solid var(--color);
}

.swiper-navigation {
  --swiper-navigation-size: 3.625em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--swiper-navigation-width, 100%);
  height: var(--swiper-navigation-size);
}

.swiper-button-prev, .swiper-button-next {
  width: var(--swiper-navigation-size);
  border-radius: calc(var(--swiper-navigation-size) / 2);
  background-color: var(--color-background);
  user-select: none;
}

.swiper-button-prev uf-icon, .swiper-button-next uf-icon {
  width: 0.75em;
}

.swiper-button-prev::after, .swiper-button-next::after {
  display: none;
}

.swiper-button-prev.hidden, .swiper-button-next.hidden, .swiper-button-disabled {
  display: none;
}

.swiper-button-prev {
  left: 1em;
}

.swiper-button-next {
  right: 1em;
}

.swiper-button-next uf-icon {
  transform: rotate(180deg);
}

.swiper-pagination {
  position: static;
  margin: 0 auto;
  transform: none !important;
  left: 0 !important;
  bottom: 0 !important;
}

.swiper-pagination[disabled] {
  pointer-events: none;
}