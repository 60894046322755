squad-lineup-view {
  --header-bar-margin: calc(var(--game-padding) * 1.5);
  --card-selected-outline: var(--color-active);
  display: block;
  height: calc(var(--real100vh, 100vh) - var(--game-navbar-height) - 3.5em - (var(--game-padding) * 2) - var(--header-bar-margin));
}

squad-lineup-view #lineup-header {
  margin-bottom: var(--header-bar-margin);
}
squad-lineup-view #lineup-header h1 {
  font-size: 1.5em;
}
squad-lineup-view #lineup-header p {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
}
squad-lineup-view #lineup-header [slot] p.active {
  color: var(--color-active);
}
squad-lineup-view #lineup-header uf-icon {
  width: 1.5em;
}
squad-lineup-view #lineup-header [slot="left"], squad-lineup-view #lineup-header [slot="right"] {
  display: flex;
  align-items: center;
}
squad-lineup-view #lineup-header [slot=left] {
  column-gap: 1.25em;
}
squad-lineup-view #lineup-header [slot=right] {
  column-gap: 2em;
}
squad-lineup-view #lineup-header [slot="left"] uf-icon, squad-lineup-view #lineup-header [slot="right"] uf-icon {
  flex-shrink: 0;
}
squad-lineup-view #lineup-header uf-icon[icon=countdown] {
  width: 1.5em;
}
squad-lineup-view #lineup-header uf-icon[icon=chevron] {
  width: 0.85em;
  transform: rotate(180deg);
}

@media (max-width: 850px) {
  squad-lineup-view #lineup-header h1 {
    display: none;
  }
}

@media (max-width: 500px) {
  squad-lineup-view #lineup-header p {
    font-size: 0.85em;
  }
  squad-lineup-view #lineup-header [slot=left], squad-lineup-view #lineup-header [slot=right] {
    column-gap: 1em;
  }
}

squad-lineup-view squad-collection {
  --squad-collection-columns: 3;
}

@media (max-width: 1150px) {
  squad-lineup-view squad-collection {
    --squad-collection-columns: 2;
  }
}

@media (max-width: 1000px) {
  squad-lineup-view {
    --game-card-scale: 0.85;
  }
}

@media (max-width: 800px) {
  squad-lineup-view {
    --game-card-scale: 1;
  }
}

@media (max-width: 650px) {
  squad-lineup-view squad-collection {
    --collection-wrap: nowrap;
    --collection-headings-display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}