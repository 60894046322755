game-lineup {
  --card-border-width: calc(0.2em * var(--game-card-scale, 1));
  --card-padding: calc(0.15em * var(--game-card-scale, 1));

  flex-grow: 1;
  position: relative;
}
game-lineup header {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
game-lineup header button.no-style {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  padding: 0.25em 0.85em;
}
game-lineup header uf-icon {
  width: 1.5em;
}
game-lineup header button.active {
  padding: 0.78em 1.1em;
  line-height: 1;
  border: none;
}

#pitch {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
}
#pitch > div {
  position: relative;
}
#pitch svg {
  width: 50em;
}
.lineup-card {
  position: absolute;
}
.lineup-card#top {
  left: calc(50% - ((var(--game-card-width) / 2) * var(--game-card-scale, 1)));
  top: -8%;
}
.lineup-card#top-left {
  left: 19.5%;
  top: 11%;
}
.lineup-card#top-right {
  right: 19.5%;
  top: 11%;
}
.lineup-card#bottom-left {
  left: 20%;
  bottom: 28%;
}
.lineup-card#bottom-right {
  right: 20%;
  bottom: 28%;
}
.lineup-card#bottom {
  left: calc(50% - ((var(--game-card-width) / 2) * var(--game-card-scale, 1)));
  bottom: 7%;
}
.lineup-card#middle {
  left: calc(50% - ((var(--game-card-width) / 2) * var(--game-card-scale, 1)));
  bottom: 45%;
}
#pitch.dragging .lineup-card {
  opacity: 0.5;
}
#pitch.dragging .lineup-card.droppable {
  opacity: 1;
}
#pitch.dragging .lineup-card.droppable div.placeholder + .lineup-card-add-label {
  display: flex;
}

game-lineup .card {
  padding: var(--card-padding);
  border-radius: 1.2em;
  border: 0.2em solid transparent;
}
game-lineup .card.active {
  border-color: var(--card-selected-outline);
}

game-lineup .card game-card-front {
  cursor: pointer;
}

.lineup-card-add-label {
  font-size: calc(1em * var(--game-card-scale));
  display: none;
  background-color: var(--color-primary);
  color: #fff;
  line-height: 1;
  position: absolute;
  top: -0.1em;
  right: -0.25em;
  justify-content: center;
  align-items: center;
  width: 1.075em;
  height: 0.75em;
  border-radius: 0.1875em;
}
.lineup-card-add-label.visible {
  display: flex;
}
.lineup-card-add-label > span {
  font-weight: 700;
  font-size: 0.55em;
}

.modifier-label {
  font-size: calc(1em * var(--game-card-scale));
  --size: 1.5em;
  position: absolute;
  top: -0.4em;
  right: -0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  background-color: #fff;
  padding: 0.2em;
  cursor: pointer;
}

game-lineup uf-icon[icon="eighteen"] {
  position: absolute;
  bottom: 5%;
  right: 3%;
  width: 1.625em;
  height: 1.625em;
}

game-lineup uf-icon[icon="twentyOne"] {
  position: absolute;
  bottom: 5%;
  right: 3%;
  width: 1.625em;
  height: 1.625em;
}

@media (min-width: 1600px) {
  #pitch svg {
    width: 60em;
  }
}
@media (max-width: 1300px) {
  #pitch svg {
    width: 40em;
  }
}

@media (max-width: 1000px) {
  #pitch svg {
    width: 31em;
  }
  .lineup-card#top-left, .lineup-card#top-right {
    top: 8%;
  }
  .lineup-card#middle {
    bottom: 40%;
  }
  .lineup-card#bottom-left, .lineup-card#bottom-right {
    bottom: 22%;
  }
}

@media (max-width: 800px) {
  .lineup-card#top {
    top: -20%;
  }
  .lineup-card#top-left, .lineup-card#top-right {
    top: 4%;
  }
  .lineup-card#top-left {
    left: 16.5%;
  }
  .lineup-card#top-right {
    right: 16.5%;
  }
  .lineup-card#bottom-left, .lineup-card#bottom-right {
    bottom: 20%;
  }
  .lineup-card#bottom-left {
    left: 15%;
  }
  .lineup-card#bottom-right {
    right: 15%;
  }
  .lineup-card#bottom {
    bottom: 5%;
  }
}

@media (max-width: 500px) {
  #pitch svg {
    width: 37em;
  }
  .lineup-card#top {
    top: -13%;
  }
  .lineup-card#top-left, .lineup-card#top-right {
    top: 5%;
  }
  .lineup-card#top-left {
    left: 20.5%;
  }
  .lineup-card#top-right {
    right: 20.5%;
  }
  .lineup-card#middle {
    bottom: 47.5%;
  }
  .lineup-card#bottom-left, .lineup-card#bottom-right {
    bottom: 27%;
  }
  .lineup-card#bottom-left {
    left: 20%;
  }
  .lineup-card#bottom-right {
    right: 20%;
  }
  .lineup-card#bottom {
    bottom: 10%;
  }
  uf-icon[icon="eighteen"] {
    bottom: 10%;
    right: 20%;
  }

  uf-icon[icon="twentyOne"] {
    bottom: 10%;
    right: 20%;
  }
}

@media (max-width: 500px) and (max-height: 1000px) {
  #pitch {
    height: auto;
    margin-top: 1.5em;
  }
}