stage-list-view {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 500px) {
  stage-list-view header-bar h3 {
    font-size: 1em;
  }
}

stage-list-view uf-icon[icon=chevron] {
  width: 1.25em;
}

stage-list-view section {
  display: flex;
  width: 100%;
  max-width: 25.125em;
  flex-direction: column;
  row-gap: 1em;
  justify-content: center;
  margin: 1em auto;
}