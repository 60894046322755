transfer-in-out {
  --nav-bullet-size: 0.5em;
  display: block;
  padding-bottom: calc(var(--selection-tray-height) + (var(--selection-tray-margin) * 2));
}

transfer-in-out uf-icon[icon="cancel"] {
  width: 1.5em;
}

transfer-in-out h1 {
  font-size: 1.5em;
  line-height: 1.3;
  text-transform: capitalize;
}

transfer-in-out h1 + span {
  color: var(--color-active);
  font-size: 1.125em;
  font-weight: 700;
}

transfer-in-out h1 + span.error {
  color: #FF284F;
}

transfer-in-out nav {
  display: flex;
  justify-content: center;
  user-select: none;
}

transfer-in-out ul {
  display: flex;
  column-gap: 1em;
  padding: 0 0 calc(var(--nav-bullet-size) + 0.125em);
  margin-top: 2.25em;
}

transfer-in-out ul > * {
  flex-shrink: 0;
}

transfer-in-out ul > li {
  position: relative;
  width: 3em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--color-soft-text);
  line-height: 1;
}

transfer-in-out ul > li span {
  font-size: 0.875em;
}

transfer-in-out ul > li.selected {
  color: var(--color);
}

transfer-in-out ul > li.selected::after {
  content: "";
  position: absolute;
  width: var(--nav-bullet-size);
  height: var(--nav-bullet-size);
  border-radius: calc(var(--nav-bullet-size) / 2);
  background-color: var(--color-primary);
  bottom: calc((var(--nav-bullet-size) * -1) - 0.125em);
}

transfer-in-out section {
  margin-top: 1.25em;
  padding: 0 3.25em;
}

transfer-in-out header {
  line-height: 1;
  display: flex;
  align-items: baseline;
  column-gap: 0.75em;
  margin-bottom: 2em;
}

transfer-in-out h2 {
  font-size: 1.5em;
}

transfer-in-out header span {
  color: var(--color-soft-text);
}

transfer-in-out #selection {
  --game-card-scale: 1.6;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(var(--game-card-width) * var(--game-card-scale)));
  justify-content: space-between;
  gap: 2.75em 2em;
  user-select: none;
}

transfer-in-out #selection > div {
  cursor: pointer;
  position: relative;
}

transfer-in-out #selection input {
  position: absolute;
  opacity: 0;
}

transfer-in-out #selection input + div {
  --size: 1.625em;
  width: var(--size);
  height: var(--size);
  background-color: #292929;
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.375em auto 0;
}

transfer-in-out #selection input:checked + div {
  background-color: var(--color-active);
}

transfer-in-out #selection input + div uf-icon[icon="tick"] {
  --tick-color: #121212;
  width: 1em;
  position: relative;
  top: 0.02em;
}

transfer-in-out transfer-tray [slot="body"] {
  overflow-x: auto;
}

transfer-in-out transfer-tray [slot="body"] > div {
  display: flex;
  align-items: center;
  column-gap: 1.25em;
}

transfer-in-out transfer-tray p {
  margin: 0;
  font-weight: 500;
  margin-left: 1.25em;
}

transfer-in-out transfer-tray p span {
  font-size: 1.25em;
}

transfer-in-out transfer-tray [slot="buttons"] {
  display: flex;
  align-items: stretch;
  height: 100%;
  column-gap: 1em;
  flex-shrink: 0;
}

transfer-in-out transfer-tray [slot="buttons"] button {
  width: 5.25em;
  padding: 0;
  font-weight: 500;
}

transfer-in-out transfer-tray [slot="buttons"] button[disabled] {
  background-color: #101010;
  border-color: #101010;
}

transfer-in-out transfer-tray [slot="buttons"] button.dark {
  background-color: #101010;
  border-color: #101010;
}

transfer-in-out transfer-tray [slot="buttons"] button span {
  font-size: 0.875em;
}
  
@media (max-width: 500px) {
  transfer-in-out h1 {
    font-size: 1em;
    line-height: 1;
  }
  transfer-in-out h1 + span {
    font-size: 0.75em;
  }

  transfer-in-out ul {
    column-gap: 0.5em;
  }

  transfer-in-out section {
    padding: 0 2.5em;
  }

  transfer-in-out #selection {
    --game-card-scale: 1.2;
  }

}