lineup-view header-bar {
  margin-bottom: 2.5em;
}

lineup-view header-bar uf-icon {
  width: 1.25em;
}

lineup-view header-bar h1 {
  font-size: 1.5em;
}

lineup-view header, lineup-view section, lineup-view footer {
  width: 25.125em;
  max-width: 100%;
  margin: 0 auto;
}

lineup-view #user-details {
  --height: 3.5em;
  background-color: var(--color-leaderboard-position-entry-selected);
  color: var(--color-leaderboard-position-entry-text-selected);
  height: var(--height);
  border-radius: calc(var(--height) / 2);
  padding: 0 1em;
  display: flex;
  align-items: center;
  column-gap: 1em;
  margin-bottom: 1.5em;
}

lineup-view #user-thumbnail-image {
  --size: 2.5em;
  width: var(--size);
  height: var(--size);
  border-radius: calc(var(--size) / 2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

lineup-view #user-details p {
  font-weight: 500;
}

lineup-view h2 {
  font-size: 1.5em;
}

lineup-view #leagues {
  margin-bottom: 2.25em;
}

lineup-view league-card {
  margin-top: 1.25em;
}

lineup-view #leagues div, lineup-view #lineup div {
  margin-top: 1em;
}

lineup-view router-link {
  display: block;
}

lineup-view router-link:not(:last-of-type) {
  margin-bottom: 1.25em;
}

lineup-view footer {
  text-align: center;
  margin-top: 2em;
}

lineup-view footer p {
  opacity: 0.5;
  margin: 0;
}