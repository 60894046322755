app-container {
  --game-padding: 1em;
  display: block;
  height: var(--real100vh, 100vh);
  overflow-y: auto;
  overflow-x: hidden;
}

app-container#intro {
  /* webpackIgnore: true */
  background-image: url("/smoke-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
}

app-container#intro-nfl, #fullscreen {
  /* webpackIgnore: true */
  background-image: url("/game-background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
}

#fullscreen {
  min-height: 100%;
  font-size: var(--fontsize);
  padding: var(--game-padding);
}

#app-loader {
  width: 100vw;
  height: var(--real100vh, 100vh);
  display: flex;
  justify-content: center;
  align-items: center;
}

#app-loader loading-spinner {
  width: 4em;
}

@media (min-width: 1000px) {
  app-container#intro-nfl, #fullscreen {
    background-size: 80em;
  }
}

@media (max-width: 800px) {
  #fullscreen {
    --fontsize: 12px;
  }
}

@media (max-width: 500px) {
  #fullscreen {
    --fontsize: 4vw;
  }
}