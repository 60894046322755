@import "./base.css";
@import "./resets.css";

/* TEXT */

h1 {
  font-size: 2.25em;
}
h2 {
  font-size: 1.875em;
}
h3 {
  font-size: 1.5em;
}

.small-text, .small-text p {
  font-size: var(--smallfontsize) !important;
}

.active-text {
  color: var(--color-active);
}

a {
  color: var(--color);
}


/* BUTTONS */

button {
  background-color: transparent;
  color: var(--color);
}
button {
  padding: 0.5em 2.2em 0.6em;
  background-color: var(--color-primary);
  color: var(--color);
  border-radius: 0.625em;
  border: 0.15em solid var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
button[disabled] {
  background-color: var(--color-background);
  color: var(--color-soft-text);
  border-color: var(--color-background);
  cursor: default;
}
button.plain {
  border-color: var(--color);
  background-color: var(--color-background);
}
button.plain[disabled] {
  border-color: #4e4e4d;
}
button.light {
  background-color: var(--color);
  color: #111111;
  border-color: var(--color);
}
button.grey {
  background-color: var(--color-background);
}
button.dark {
  background-color: var(--color-disabled);
}
button.active {
  background-color: var(--color-active);
  color: #000000;
}
button.no-border {
  border: none;
}
button.no-style {
  all: unset;
  cursor: pointer;
}



/* FORMS */

.input {
  position: relative;
}
.input:not(:last-of-type) {
  margin-bottom: 2.5em;
}
input {
  display: block;
  background-color: transparent;
  width: 100%;
  border-bottom: 0.15em solid var(--color);
  color: var(--color);
  padding: 0.5em 0 0.2em;
}
input::placeholder {
  color: var(--color);
  opacity: 0.45;
}
input.error {
  border-color: var(--color-error);
}
input + span {
  display: none;
  position: absolute;
  color: var(--color-error);
  font-size: var(--smallfontsize);
  margin-top: 0.25rem;
}
input.error + span {
  display: block;
}



/* GENERAL */

.clickable {
  cursor: pointer;
}

.block {
  border-radius: 1.25em;
  background-color: var(--color-background);
}